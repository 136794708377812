table.calendar a,
table.detail a{
  color:var(--color-third);
}

table.calendar a.restrant{
  color:var(--color-forth);
}

table.calendar{
  width: 100%;
  background-color: #fff;

}

table.calendar.list td,
table.calendar.list th{
  width: auto;
}
table.calendar td{
  padding: .5em;
  border: 1px solid #ccc;
  color:#333;
  background-color: #fff;
  text-align: left;
}

table.calendar td div{
  margin-bottom: 5px;
}

table.calendar tr.month td{
  background-color: #aaa;
  color:#fff;
}

table.calendar td.has_book{
  background-color: var(--color-selected);
  border-bottom: 1px solid var(--color-selected);
  color:#fff;

}
table.calendar.day th{
  background-color: #eee;
}
table.calendar.day td{
    text-align: left;
}
table.calendar td.has_book a{
  color:#fff;

}
table.calendar.day a{
  color:var(--color-forth);
}
table.detail{
  width: 100%;
  font-size: .9em;
}

table.detail td,
table.detail th{
  padding: 1em;
  border: 1px solid #ccc;
  color:#333;
  font-size: .9em
}
table.detail th{

}
table.detail div.input
{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
}
table.detail div.input.small{
  max-width: 200px;
}
table.detail th.restrant{
  color:#fff;
  background-color: var(--color-forth);
}
table.detail th.bbq{
  color:#fff;
  background-color: var(--color-third);
}

table.detail th{
  background-color: #ddd;
}
table.detail.list td,
table.detail.list th{
  padding: .25em;
}
table.detail.list th{
  background-color: #eee;
}

.notice{
  font-weight: bold;
  color:#ff0000;
}

.list-title{
  font-weight: bold;
  border-top:  1px dotted #777;
  padding-top: 5px;
  width: 100%;
}