div.notice{
  color:  var(--color-selected);
  font-size: .8em;
  margin-bottom: 1em;
}
div.notice i{
  font-size: 2em;
}
section.order{
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
section.order h2{
  padding: .5em;
  background-color: #333;
  color:#fff;
  text-align: center;
  font-size: 1.2em;
  width: 100%;
    box-sizing: border-box;
}

.site-select{
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
}

.site-select div{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  align-items: center;
}
.site-select div.not_available{
  color:#777;
  background-color: #ddd
}
.site-select-box{
  width: 40px;
}
.site-select div span.site-name{
  width: 280px;
  margin-left: 5px;
}




div.site-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
  width: 100%;
}
div.site-box div.site-image{
  width: 250px;
}
div.site-box div.site-image img{
  width: 100%;
}
div.site-box div.site-content{

  padding-top: 5px;
  padding-left: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

div.site-box h3{
  text-align: left;
  margin-bottom: 0;
}

div.input-box{
  display: flex;
  flex-direction: row;
  padding: .8em .5em;
  box-sizing: border-box;
}
div.input-box.checkbox{
    align-items: center;
    border: none;
    margin-bottom: 0;
    padding-bottom: 0;
    margin-top: 0;
}
div.input-box label.check-parts{
  margin: 0;
}

div.input-box.menu_select{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 1em;
  align-items: center;
  border: none;
}

div.description{
  font-size: .8em;
  line-height: 2em;
}

div.price{
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

div.price  div.total_price_div,
div.price div.total_price{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

div.price div.total_price_div{
  margin-bottom: 20px;
}

div.price div.total_price{
  font-size: 1.5em;
  border-top: 1px solid #ddd;
  padding-top: 20px;
}
.right{
  text-align: right;

}
.fa-spin{
  font-size: 2em;
  margin-top: 20px;
}
div.payselect{
    margin: 5px 0 30px 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #ddd;
}

div.payselect div{
  margin-right: 10px;
  padding: 1em;
}
div.payment_form_body{
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  width: 100%;
  justify-content: space-between;
}
.form-box-item{
  width: 30%;
}
.form-no-wriap{
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
}
.label-def{
  font-size: .8em;
  margin-bottom: 5px;
}
.btns{
    width: 100%;
    text-align: center;
    margin: 20px 0
}
.thanks{
  margin-bottom: 10px;
}
.thanks p{
  margin-bottom: 2em;
  line-height: 1.2em;
}
button.normal{
  width: 200px;
  font-size: 1em;
  background-color: var(--color-link);
  margin: 0 auto;
}

div.selected-box{
  background-color: #333;
  width: 100%;
  height:60px;
  opacity: .9;
  position: fixed;
  bottom: 0;
  left:0;
  z-index: 200;
}
div.selected-box div.inner{
  width: 800px;
  max-width: 100%;
  height: 100%;
  margin: 0 auto;
  color:#fff;
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.not-available{
  background-color: #ddd;
  color:#777;
}
div.site-info{
  display: flex;
  flex-direction: row;
  align-items: center;
}
 div.select_group{
  width: 150px;
}
@media screen and (max-width: 639px) {
  .site-select div span.site-name{
    width: 220px;
    margin-left: 0;
  }
  .site-select table td,
  .site-select table th{
    display: block;
  }
  .site-select table th{
    text-align: left;
    padding-left: 1em;
  }
  span.form-name{
    width: 100%;
    display: block;
  }
  .form div{
    flex-direction: column;
  }
  section.order{
    width: 100%;
    box-sizing: border-box;
  }
  div.site-box{
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;

  }
  div.site-box div.site-image{
    width: 100%;
    text-align: center;
  }
  div.site-box div.site-content{
    width: 100%;
  }
  div.selected-box{
    font-size: .8em;
    text-align: center;
  }
}
