#calendar{
  margin: 20px auto;
  padding: 3em 2em 3em 2em;
  table-layout:auto;
}

.month-selector{
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  margin-bottom: 2em;
}
.month-selector i{
  color:#666;
    cursor: pointer;
}
.month-selector div.navigator{
  cursor: pointer;
}
table.calendar{

}
table.calendar td,
table.calendar th{
  padding: 1em;
  text-align: center;
}

.full{
  width: 100%;
}

.calendar thead{
  background-color: #eee;

}
.calendar thead td{
    padding: 1em;
}
.calendar td,
.calendar th {
  text-align: center;
  line-height: 1.2em;
  border:1px solid #ddd;
  width: 100px;
  font-size: .8em;
}
.calendar th.sunday{
  background-color: #ffc0cb;
}
.calendar th.saturday{
  background-color: #e0ffff;
}
.calendar div.date{
  font-weight: bold;
  margin-bottom:1em;
}
.calendar div.holidayClass{
    color:#dc143c;
}
.calendar div.sunday{
  color:#dc143c;
}
.calendar div.saturday{
  color:#0000cd;
}
.calendar div.available{
  margin: .5em 0;
}

.calendar div.no-room{
padding: 1em;
}
.calendar div.has-room{
    padding: 1em;
  cursor: pointer;

}
.calendar div.has-room div.roomnum,
.calendar div.has-room div.available{
    color:#4169e1;
    font-weight: bold;
}
.calendar div.has-room:hover{
  background-color: #eee;
}
.calendar div.has-room div.less-num{
  color:#dc143c;
}
.calendar div.date_selected{
  background-color: #FDFFAB;
}
.calendar th{
  background-color: #fff;
  font-weight: normal;
}
.calendar td{
  background-color: #eee;
}
.calendar td.available{
  background-color: #fff;
}
.calendar td.unavailable{
  background-color: #ddd;
}
.calendar td.selected{
  background-color: var(--color-selected);
  color:#fff;
}
/*
Book Number Select
*/
#book-num-select-box{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content:center;
}
.book-num-select{
  width: 100px;
}
div.input-innner{
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.input-box {
    display: flex;
    flex-direction: row;
    padding: .8em .5em;
    box-sizing: border-box;
}

div.select_group{
  width: 150px;

  padding: .8em .5em;
}

@media screen and (max-width: 639px) {

  #calendar {
      margin: 20px auto;
      padding: 0 1em;
      width: 100%;
      box-sizing:border-box;
  }
  .month-selector{
    width: 100%;

  }


  .calendar td,
  .calendar th {
    padding: .2em;
    width:45px;
    font-size: 10px;
    box-sizing: border-box;
  }
  .calendar div.no-room{
    padding: 0;
  }
  .calendar div.has-room{
      padding: 0;
  }
  /*.calendar td,
  .calendar th {
    display: block;
    width: 100%;
  }*/
}
