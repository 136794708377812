

table.list{
  width: 100%;
  background-color: #fff;
  font-size: .9em;
}

table.list td,
table.list th{
  width: auto;
}
table.list td,
table.list th{
  padding: .5em;
  border: 1px solid #ccc;
  color:#333;
  background-color: #fff;
  vertical-align:middle;

}
table.list tr.delivery td{
  background-color: #eff3c6;
}

table.list tr.unpaid td{
  background-color: #eee;
}
table.list tr.s1 th{
   background-color: #f8e1f4;
   color:#000;
}
table.list tr.s2 th{
   background-color: #97e5ef;
   color:#fff;
}
table.list tr.s3 th{
   background-color: #ffecc7;
   color:#fff;
}
table.list table.inner{
  width: 100%;
}
table.list table.inner td{
  border:none;
  border-bottom:1px solid #ddd;
}
table.list table.inner tr:last-child td{
  border:none;
}
table.list td.check{
  font-size: .9em;
  width: 90px;
}
table.list td.check div{
  color:#aaa;
  width: 100%;
  cursor: pointer;
  margin: 5px 0;
}
table.list td.check div:hover{
  color:var(--color-dengerous);
}
td.check i{
  font-size: 1.2em;
}

td.image{
  max-width: 50px;
}


td.image img{
  width: 100%;
}


table.list input[type="text"] {
display: block;
width: 100%;
border: none;
color: inherit;
background-color: inherit;
font-size: 1em;
padding: 0;
}
table.list input[type="text"].sml {
  width: 50px;
  text-align: right;
}
table.list input[type="text"]:hover {
  border: 1px solid #efefef;
  color: #000;
  background: #fcfcfc;
  padding: 1.5em 5px;
  font-size: .8em;
}

table.list input[type="text"]:focus {
display: block;
border: 1px solid #e6e6e6;
color: #000;
background: #f9f9f9;
box-sizing: border-box;
-webkit-appearance: none;
box-shadow: none;
font-size: .8em;
  padding: 1.5em 5px;
}


div.media-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
div.media-list-item{
  width: 200px;
  height: 150px;
  margin: 5px;
  align-items: flex-start;
}

div.media-list-item add{
  display: flex;
  justify-content: center;
  align-items: center;
}
div.media-list-item img.selected{
  border: 3px solid var(--color-third);
  opacity: .4;
}
div.media-list-item img{
  width: 100%;
   object-fit: fill;
   cursor: pointer;
}
div.sms-button{
  cursor: pointer;
}
div.sms-area{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  box-sizing: border-box;
}
div.sms-area textarea{
  width: 400px;
  height: 180px;
}
div.sms-area input[type=text]{
    width: 500px;
}
div.sms-area div.select-box{
  width: 500px;
  color:#333;
  font-size: .8em;
  margin-top: 5px;
}
div.sms-area div.counter{
  margin: 20px auto;
  font-size: .8em;
}
div.sms-area div.button{
  width: 200px;
  display: flex;
  justify-content: center;
}

div.status-message{
  font-weight: bold;
  font-size: 1.2em;
  text-align: center;
  color:#f00;
}
div.status-message-button{
  font-size: .8em;
  text-align: center;
  color:var(--color-link);
  cursor: pointer;
}
